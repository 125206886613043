@import url('https://fonts.googleapis.com/css2?family=Newsreader:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'NewsReader', serif;
}

.container {
  display: flex;
  justify-content: center;
  font-family: 'Inter', serif;
}

.cube {
  margin-top: 10%;
  position: relative;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d; /* Enable 3D effect */
  transform-origin: center; /* Ensure proper rotation */
}

.block {
  width: 100%;
  transition: height 0.5s ease;
}

.content-wrapper {
  transition: opacity 0.5s ease, height 0.5s ease;
  opacity: 1;
  overflow: hidden;
}

.content-wrapper.fade-out {
  opacity: 0;
}


.face {
  position: absolute;
  width: 100px;
  height: 100px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  justify-items: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  backface-visibility: hidden; /* Hide the back of each face */
  overflow: hidden;
  line-height: 1;
}

.front {
  transform: translateZ(50px);

}

.back {
  transform: rotateY(180deg) translateZ(50px);

}

.left {
  transform: rotateY(-90deg) translateZ(50px);
}

.right {
  transform: rotateY(90deg) translateZ(50px);


}

.top {
  transform: rotateX(-90deg) translateZ(50px);

}

.bottom {
  transform: rotateX(90deg) translateZ(50px);
}
.controls {
  margin-top: 20px;
}

.title_box {
  display: flex;
}

/* Container for the sliding bar */
.sliding-bar-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3a393d;
  border-radius: 25px;
  width: 200px;
  height: 30px;
  border: 2px solid #3d3a3a;
  cursor: pointer;
  caret-color: transparent;
}

/* The sliding bar itself */
.sliding-bar {
  position: absolute;
  width: 100px;
  height: 30px;
  left: 0%;
  background-color: #991414;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.5s linear;
}

/* Active state (on the left) */
.sliding-bar.active {
  transform: translateX(0%); /* Stays on the left */
  background-color: #636863;
}

/* Inactive state (on the right) */
.sliding-bar.inactive {
  transform: translateX(100%); /* Moves to the right */
  background-color: #636863;
}

/* Sliding label inside the bar */
.label {
  position: relative;
  width: 10%;
  background-color: transparent;
  border-radius: 25px;
  text-align: center;
  line-height: 5px;
  font-weight: bold;
  color: #EEEEEE;
  transition: transform 0.3s ease, color 0.3s ease;
  font-family: 'Inter', serif;
  font-size: small;
}

.label1 {
  margin-left: -2%;
  text-align: center;
}

.label2 {
  margin-right: 20%;
  text-align: center;
}

.labelContainer {
  display: flex;
  width: 80%;
  height: 100%;
  justify-content: space-between;
  z-index: 1;
}


html.custom-scroll, body.custom-scroll {
  height: 100%;
  overflow: hidden; /* Prevent default scrollbars and ensure consistent custom scrolls */
}

body.custom-scroll {
  overflow-y: auto; /* Enable vertical scrolling for the page */

/* Firefox-specific scrollbar styles */
  scrollbar-width: thin; /* 'auto' | 'thin' | 'none' */
  scrollbar-color: #4b4b4d #011120; /* thumb color, track color */
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #011120;
}

::-webkit-scrollbar-thumb {
  background: #4b4b4d;
  border-radius: 4px;
}


.it {
  font-style: italic;
}

.bl {
  font-weight: 700px;;
}
.h1 {
  font-family: 'NewsReader', serif;
  font-weight: 700;
}

.p {
  font-family: 'NewsReader', serif;
  font-weight: 400;
  color: #EEEEEE;
}

.flex-container {
  display:flex;
  justify-content: space-between;
  padding: 0px;
}

.item {
  padding: 0px;
}

.search-bar {
  justify-content: space-between;
}

.bigTable {
  border: #282c34;
  border-width: 5px;
  padding: 10px;
}

html, body, #root {
  margin: 0;
  padding: 0%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #011120  ;
  justify-items: center;
  overflow-y: auto;
  scroll-behavior: smooth;
  caret-color: transparent;
}

.header {
  background-color: #011120;
  position: fixed;
  width: 350px;
  height: 100vh;
  right: 60%;
}

.nav {
  border-color: #011120;
  display: flex;
  flex-direction: column;
  
  
}

.logo {
  font-size: 45px;
  font-family: 'Inter', serif;
  font-weight: 700;
  color: #c8cacc ;
}

.invisText {
  color:transparent;
}
.bold {
  font-weight: 500;
  color: #a2a1aa;
  font-family: 'Inter', serif;
  font-size: 16px;
}
.site {
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 70%;
  position: relative;
  justify-content: center;

}
.button {
  padding-top: 0%;
  width: 100px;
  height: 50px;
  color: #7c878e ;
  background-color: #011120;
  border: 0cm;
  transition: 0.5s all;
  text-align: left;
  vertical-align: middle;
  
}

.contactBox {
  width: 500px;
  border: #404340;
  padding: 2%;
  border-width: 2px;
  border-style:solid;
  border-radius: 15px;
  
  
}
.iconContainer {
  margin-top: 30%;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.icon {
  width: 20px;
  height: auto;
}

.icon:hover {
  cursor: pointer;
}
.p1 {
  font-family: 'Inter', serif;
  font-size: 20px;
}
.main {
  overflow-y: auto;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: left;
  margin-top: 50px;
  margin-left: 60%;
  transition: transform 0.3s smooth;
  max-width: 800px;
}

.heading {
  width: 100px;
  font-size: 30px;
  color: #f7fafc ;
  font-family: 'Inter', serif;
}

.aboutText {
  font-size: 15px;
  color: #6b697b   ;
  font-family: 'Inter', serif;
  word-spacing: 0.35mm;
  letter-spacing: 0.04mm;
  justify-content: space-evenly;
  transform: all 0.4s;
  justify-items: left;
  text-align: justify;
  opacity: 1;
  transition: all 0.5s ease;
  margin-left: 20px;
  
  
  
}

.title {
  font-size: 25px;
  color: #b6b6b6;
  font-family: 'Inter', serif;

}

.tileContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0px;
}
.submitButtonBox {
  justify-items: center;
  text-align: right;
}
.submitButton {
  background-color: #252426;
  border-width: 0px;
  color: #908e8e;
  height: 20px;
  border: rgb(118, 118, 118);
  border-width: 1px;
  border-style: solid;
  width: 356.2px;
}
.tile {
  margin-top: 30px;
  width: 400px;
  aspect-ratio: 3;
  background-color: #011120;
  border-color: #282b2d;
  border-width: 2px;
  border-style: solid;
  overflow: hidden;
  margin-top: 2%;

  font-family: 'Inter', serif;
  transition: all 0.3s;

  caret-color: transparent;

  
  display: flex;
}

.imageContainer {
  flex: 0 0 40%;
  height: auto; 
  overflow: hidden;
  align-content: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px #222831;
  
  
  
}

.tileImage {
  width: 95%;
  height: 95%;
  object-fit: cover;
}

.tileTitle {
  color: #999999;
  margin-left: 5%;
  margin-top: 6%;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Inter', serif;
}
.prompt-box {
  justify-content: space-between;
  display: flex;
  margin-top: 5px;
}

.form {
  width: 100%;
  justify-items: left;
}
.formInput {
  width: 350px;
  background-color: #252426;
  outline-color: rgb(118, 118, 118);
  outline-width: 2px;
  outline-style: solid;
  color: #EEEEEE;
  font-family: 'Inter', serif;
  caret-color: whitesmoke;
}
textarea {
  resize: both; /* Allows resizing in both directions */
  width: 350px;
  height: 100px;
  background-color: #252426;
  color: #EEEEEE;
  font-family: 'Inter', serif;
  caret-color: whitesmoke;
}

.emailInput {
  height: 20px;
}
.tileTextBox {
  flex: 1; /* Take up the remaining space */
  display: flex;
  flex-direction: column;
}
.tileText {
  margin-left: 5%;
  margin-top: 5%;
  margin-right: 5%;
  text-align: justify;
  color: #b0b0b0;
  font-size: 12px;
  font-family: 'Inter', serif;
}

.tile:hover {
  margin-left: 1%;
  cursor: pointer;
  background-color: #515356;
}

.headingContainer {
  width: 100%;
}

.button:hover {
  background-color: #222831;
  cursor: pointer;
}

@media screen and (max-width: 1250px) { /* Adjust the max-width as needed */

  .site {
    flex-direction: column;
  }
  .header {
    position: static; /* Makes the sidebar static */
    width: 100%; /* Adjust to fit the smaller layout */
    height: auto; /* Let the content define the height */
  }

  .main {
    margin-left: 0; /* Remove the space for the fixed sidebar */
  }

}

@media screen and (max-width: 750px) { /* Adjust the max-width as needed */

.contactBox {
  width: 400px;
}
textarea {
  width: 200px;
}

.bold {
  font-size: 12px;
}

.submitButton {
  width: 206px;
}

.form {
  justify-items: center;
}

}
@media screen and (max-width: 750px) { /* Adjust the max-width as needed */

  .title_box {
    flex-direction: column;
    align-items: center;
  }

  .heading {
    text-align: center;
  }

  .sliding-bar-container {
    margin-top: 15px;
  }

  .headingContainer {
    justify-items: center;
    align-items: center;
  }

  .tileContainer {
    justify-content: center;
    align-items: center;
  }

  .tileTitle {
    font-size: 15px;
  }

  .tileText {
    font-size: 11px;
  }

  .site {
    width: 90%;
  }
  
}

@media screen and (max-width: 480px) { /* Adjust the max-width as needed */

  .contactBox {
    width: 300px;
  }

  .tile {
    width: 300px;
    margin-top: 2%;
  }

  .tileText {
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) { /* Adjust the max-width as needed */

  .contactBox {
    width: 280px;
  }

  .tileContainer {
    margin-top: 5%;
  }

  .tile {
    width: 300px;
    margin-top: 2%;
  }

  .tileText {
    font-size: 8px;
  }

  .tileTitle {
    font-size: 12px;
  }

  textarea {
    width: 150px;
  }

  .submitButton {
    width: 157px;
  }




}
